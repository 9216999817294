.main-content-container{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.children-container{
    position: relative;
    flex-grow: 2;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.notification{
    transition: all 0.5s;
    border-radius: 20px;
    z-index: 1000;
    position: absolute;
    top: 100px;
    right: -400px;
    padding: 24px;
    background: rgba(177, 177, 177, 0.3);
    backdrop-filter: blur(30px);
}

.notification.shown{
    right: 20px;
}